import { I18nMessagesSelect } from './messages.selects';
import { I18N_INFLUENCER_TIMELINE_EN } from './modules/influencer-timeline.i18n';
import { I18N_RELATIONSHIP_TIMELINE_EN } from './modules/relationship-timeline.i18n';
import { I18N_INFLUENCER_PROFILE_EN } from './modules/relationship-profile.i18n';
import { I18N_STEP_INFLUENCER_EN } from './pipes/step-influencer.i18n';
import { I18N_SEARCH_EN } from './modules/search.i18n';
import { I18N_AGE_RANGE_EN } from './modules/age-range.i18n';
import { Injectable } from '@angular/core';
import { I18N_MODAL_CONFIRM_DELETE_MESSAGE_EN } from './modules/modal-confirm-delete.i18n';
import { I18N_INSTAGRAM_PERMISSIONS_EN } from './modules/instagram-permissions.i18n';
import { I18N_IQDATA_INTERESTS_CATEGORIES_EN } from './modules/iqdata-interests-categories.i18n';

@Injectable({
    providedIn: 'root'
})
export class I18nMessagesSelectEnglish implements I18nMessagesSelect {
    public get StepInfluencer() {
        return I18N_STEP_INFLUENCER_EN;
    }

    public get InfluencerTimeline() {
        return I18N_INFLUENCER_TIMELINE_EN;
    }

    public get InfluencerProfile() {
        return I18N_INFLUENCER_PROFILE_EN;
    }

    public get RelationshipTimeline() {
        return I18N_RELATIONSHIP_TIMELINE_EN;
    }

    public get Search() {
        return I18N_SEARCH_EN;
    }

    public get AgeRange() {
        return I18N_AGE_RANGE_EN;
    }

    public get ModalConfirmDeleteMessage() {
        return I18N_MODAL_CONFIRM_DELETE_MESSAGE_EN;
    }

    public get InstagramPermissions() {
      return I18N_INSTAGRAM_PERMISSIONS_EN;
    }

    public get IQDataInterestsCategories() {
        return I18N_IQDATA_INTERESTS_CATEGORIES_EN;
    }
}
