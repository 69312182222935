export const I18N_IQDATA_INTERESTS_CATEGORIES_PT = {
    'TELEVISION & FILM': 'Televisão e Cinema',
    'MUSIC': 'Música',
    'SHOPPING & RETAIL': 'Compras e Varejo',
    'COFFEE, TEA & BEVERAGES': 'Café, Chá e Bebidas',
    'CAMERA & PHOTOGRAPHY': 'Câmera e Fotografia',
    'CLOTHES, SHOES, HANDBAGS & ACCESSORIES': 'Roupas, Sapatos, Bolsas e Acessórios',
    'BEER, WINE & SPIRITS': 'Cerveja, Vinho e Destilados',
    'SPORTS': 'Esportes',
    'ELECTRONICS & COMPUTERS': 'Eletrônicos e Computadores',
    'GAMING': 'Jogos Eletrônicos',
    'ACTIVEWEAR': 'Roupas Esportivas',
    'ART & DESIGN': 'Arte e Design',
    'TRAVEL, TOURISM & AVIATION': 'Viagens, Turismo e Aviação',
    'BUSINESS & CAREERS': 'Negócios e Carreiras',
    'BEAUTY & COSMETICS': 'Beleza e Cosméticos',
    'HEALTHCARE & MEDICINE': 'Saúde e Medicina',
    'JEWELLERY & WATCHES': 'Joias e Relógios',
    'RESTAURANTS, FOOD & GROCERY': 'Restaurantes, Comida e Mercearia',
    'TOYS, CHILDREN & BABY': 'Brinquedos, Crianças e Bebês',
    'FITNESS & YOGA': 'Fitness e Yoga',
    'WEDDING': 'Casamento',
    'TOBACCO & SMOKING': 'Tabaco e Fumar',
    'PETS': 'Animais de Estimação',
    'HEALTHY LIFESTYLE': 'Estilo de Vida Saudável',
    'LUXURY GOODS': 'Artigos de Luxo',
    'HOME DECOR, FURNITURE & GARDEN': 'Decoração de Casa, Móveis e Jardim',
    'FRIENDS, FAMILY & RELATIONSHIPS': 'Amigos, Família e Relacionamentos',
    'CARS & MOTORBIKES': 'Carros e Motocicletas'
};

export const I18N_IQDATA_INTERESTS_CATEGORIES_EN = {
    'TELEVISION & FILM': 'Television & Movies',
    'MUSIC': 'Music',
    'SHOPPING & RETAIL': 'Shopping & Retail',
    'COFFEE, TEA & BEVERAGES': 'Coffee, Tea & Beverages',
    'CAMERA & PHOTOGRAPHY': 'Camera & Photography',
    'CLOTHES, SHOES, HANDBAGS & ACCESSORIES': 'Clothes, Shoes, Handbags & Accessories',
    'BEER, WINE & SPIRITS': 'Beer, Wine & Spirits',
    'SPORTS': 'Sports',
    'ELECTRONICS & COMPUTERS': 'Electronics & Computers',
    'GAMING': 'Gaming',
    'ACTIVEWEAR': 'Activewear',
    'ART & DESIGN': 'Art & Design',
    'TRAVEL, TOURISM & AVIATION': 'Travel, Tourism & Aviation',
    'BUSINESS & CAREERS': 'Business & Careers',
    'BEAUTY & COSMETICS': 'Beauty & Cosmetics',
    'HEALTHCARE & MEDICINE': 'Healthcare & Medicine',
    'JEWELLERY & WATCHES': 'Jewellery & Watches',
    'RESTAURANTS, FOOD & GROCERY': 'Restaurants, Food & Grocery',
    'TOYS, CHILDREN & BABY': 'Toys, Children & Baby',
    'FITNESS & YOGA': 'Fitness & Yoga',
    'WEDDING': 'Wedding',
    'TOBACCO & SMOKING': 'Tobacco & Smoking',
    'PETS': 'Pets',
    'HEALTHY LIFESTYLE': 'Healthy Lifestyle',
    'LUXURY GOODS': 'Luxury Goods',
    'HOME DECOR, FURNITURE & GARDEN': 'Home Decor, Furniture & Garden',
    'FRIENDS, FAMILY & RELATIONSHIPS': 'Friends, Family & Relationships',
    'CARS & MOTORBIKES': 'Cars & Motorbikes'
};
